<template>
  <div class="login_container">
    <form action="/">
      <van-search v-model="value" show-action shape="round" placeholder="请输入搜索关键词" @search="onSearch" @keyup="onkeyup" @click="onClick" @cancel="onCancel" @clear="onClear"/>
    </form>
    <div v-show="flag">
      <van-cell-group>
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          :error.sync="error"
          error-text="请求失败，点击重新加载">
          <van-cell v-for="item in searchList" :key="item.index">
            <template slot="label">
                <span>规格：{{item.specs}}</span>
              </template>
              <template slot="title">
                <div style="display:block;">
                  {{item.itemName}}
                  <span style="color:#57c4b7; float:right">价格：{{item.price}}元</span>
                </div>
              </template>
          </van-cell>
        </van-list>
      </van-cell-group>
    </div>
    <div style="font-size: 12px; color: #D3D3D3; margin: 1% 40%; width: auto; height: 40px;"><span>&copy;拓实银医</span></div>
  </div>
</template>
<script>
// import { Toast } from "vant";
import ajax from '../../lib/ajax';
export default {
  data() {
    return {
      value: "",
      loading: false,
      finished: false,
      error: false,
      flag: false,
      list: [],
      searchList: []
    };
  },
  created(){
    this.onLoad();
  },
  methods: {
    async onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        for (let i = 0; i < 0; i++) {
          this.list.push(this.searchList.length + 1);
        }

        // 加载状态结束
        this.loading = false;

        // 数据全部加载完成
        if (this.searchList.length == this.searchList.length) {
          this.finished = true;
        }
      }, 1000);
    },
    async onSearch() {
      if (this.value === "") {
        this.flag = true;
        this.searchList = this.list;
      } else {
        await ajax.get("/Api/QueryService/QueryItems?input="+this.value)
        .then(res => {
          this.searchList = res.data.result;
          console.log(this.searchList);
          this.list.forEach(el => {
            let searchIndex = -1;
            // let searchChar = this.value.split('');
            // searchChar.forEach((item)=>{
            searchIndex = el.undrugName.indexOf(this.value);
            if (searchIndex >= 0) {
              this.searchList.push(el);
              // indexList.push(el);
              // searchIndex=-1;
            }
            //console.log(indexList);
            // });
          });
          //去重
          //  let obj = {}
          //     this.searchList = this.searchList.reduce (function(item,next){
          //     obj[next.Pay] ? ' ' : obj[next.Pay]  = true && item.push(next)
          //     return item;
          // },[]);
          this.flag = true;
          })
      }
    },
    onClear() {
      this.searchList = this.list;
    },
    onCancel() {
      this.searchList = [];
      this.flag = false;
      // Toast("取消");
    },
    onkeyup() {
      if (this.value === "") {
        this.searchList = this.list;
        this.flag = true;
      }
    },
    onClick() {
      if (this.value === "") {
        this.searchList = this.list;
        this.flag = true;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.search_result {
  display: none;
}
</style>
